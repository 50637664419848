import styled from 'styled-components';

export const Container = styled.nav`
  ul {
    display: flex;
    list-style: none;
    padding: 0;
    .btn {
      background: #2cabde;
      color: #fff;
      border-radius: 50px;
      padding: 10px 15px;
      -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      &:hover {
        background: #1a789e;
        box-shadow: 0 2px 10px rgba(0,0,0,0.3);
        a {
          text-decoration: none;
          border-bottom: 0px !important;
        }
      }
      a {
        color: #fff;
      }
    }
    li {
      font-size: 1rem;
      & + li {
        margin-left: 1.8rem;
      }
      a {
        color: #42413d;
        padding-bottom: 2px;
        -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        &:hover {
          text-decoration: none;
          border-bottom: 1px solid;
          border-color: rgba(66, 65, 61, 0.3);
        }
      }
    }
  }
`;
