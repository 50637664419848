import React from 'react';
import { Consumer } from 'store/createContext';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import posed from 'react-pose';
import { Container } from './header.css';
import Nav from 'components/header/nav';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const maxHeight = {
  maxHeight: '120px',
};

const Header = ({ data }) => (
  <AnimatedContainer>
    <Container style={maxHeight} className="pt-4 pb-4">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-6 col-sm-4 col-md-2">
            <Link to="/">
              <Img
                fluid={data.fileName.childImageSharp.fluid}
                alt="Sell it on"
              />
            </Link>
          </div>
          <div className="col-auto">
            <Consumer>
              {({ isLoggedIn, toggleLoginStatus }) => (
                <Nav
                  isLoggedIn={isLoggedIn}
                  toggleLoginStatus={toggleLoginStatus}
                />
              )}
            </Consumer>
          </div>
        </div>
      </div>
    </Container>
  </AnimatedContainer>
);

Header.propTypes = {
  data: PropTypes.object.isRequired,
};

const HeaderWithQuery = () => (
  <StaticQuery
    query={graphql`
      query {
        fileName: file(relativePath: { eq: "logo.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Header data={data} />}
  />
);

export default HeaderWithQuery;
