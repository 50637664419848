import React from 'react';
import { Link } from 'gatsby';

const footerStyle = {
  backgroundColor: '#272626',
  marginTop: '10rem',
};

const listNone = {
  listStyle: 'none',
  padding: '0 1rem',
};

const Footer = () => (
  <footer style={footerStyle} className="py-5">
    <div className="container py-5">
      <div className="row">
        <ul className="d-flex w-100 text-center align-items-center justify-content-center mb-5 p-0">
          <li style={listNone}>
            <Link className="font-weight-bold text-white" to="/">
              Browse cars
            </Link>
          </li>
          <li style={listNone}>
            <Link className="font-weight-bold text-white" to="/listcar">
              List a car
            </Link>
          </li>
          <li style={listNone}>
            <Link className="font-weight-bold text-white" to="/terms">
              Terms
            </Link>
          </li>
        </ul>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6 offset-0 offset-lg-3">
          <p className="text-center text-white font-weight-bold">
            Sell it on Ltd. Registered address 2 Lakeview Stables St. Clere,
            Kemsing, Sevenoaks, United Kingdom, TN15 6NL
          </p>
          <p className="text-center text-white font-weight-bold">
            Company Number - 11547831
          </p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
