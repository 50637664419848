import React, { Component } from 'react';
import { navigate } from 'gatsby';
import { Consumer } from 'store/createContext';
import { Link } from 'gatsby';
import { Container } from './nav.css';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignOutAlt,
  faUser,
  faSignInAlt,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const actionBtn = {
  background: 'transparent',
  margin: '0px',
  padding: '0px',
  border: '0px',
};

const cursor = {
  cursor: 'pointer',
};

const ITEM_HEIGHT = 48;

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      open: false,
      anchorEl: null,
    };
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleNavigate = this.handleNavigate.bind(this);
  }

  handleClick() {
    localStorage.removeItem('appState');
    this.props.toggleLoginStatus();
  }

  handleMenuClick(e) {
    this.setState({
      open: true,
      anchorEl: e.currentTarget,
    });
  }

  handleMenuClose() {
    this.setState({
      open: false,
      anchorEl: null,
    });
  }

  handleNavigate(e) {
    navigate(e);
  }

  render() {
    const handleMenuClick = this.handleMenuClick;
    const handleMenuClose = this.handleMenuClose;
    const handleNavigate = this.handleNavigate;
    const { open, anchorEl } = this.state;
    return (
      <Consumer>
        {({ isLoggedIn }) => (
          <Container>
            <ul className="m-0 d-none d-md-flex align-items-center">
              <li>
                <Link to="/">Browse cars</Link>
              </li>
              <li>
                <Link to="/listcar">List a car</Link>
              </li>
              {isLoggedIn && (
                <li className="btn">
                  <Link to="/account" style={actionBtn}>
                    <FontAwesomeIcon
                      style={cursor}
                      className="text-white mr-2"
                      icon={faUser}
                    />
                    Account
                  </Link>
                </li>
              )}
              <li className={`btn ${isLoggedIn ? 'ml-1' : ''}`}>
                {isLoggedIn ? (
                  <React.Fragment>
                    <button
                      className="text-white"
                      style={actionBtn}
                      onClick={this.handleClick.bind(this)}
                    >
                      <FontAwesomeIcon
                        style={cursor}
                        className="text-white mr-2"
                        icon={faSignOutAlt}
                      />
                      Logout
                    </button>
                  </React.Fragment>
                ) : (
                  <Link to="/signup">
                    {this.state.submitting ? (
                      <CircularProgress />
                    ) : (
                      'Register Dealership'
                    )}
                  </Link>
                )}
              </li>
              {!isLoggedIn && (
                <li className="btn ml-1">
                  <Link to="/login" style={actionBtn}>
                    <FontAwesomeIcon
                      style={cursor}
                      className="text-white mr-2"
                      icon={faSignInAlt}
                    />
                    Login
                  </Link>
                </li>
              )}
            </ul>
            <div className="m-0 d-block d-md-none">
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleMenuClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                className="d-block d-md-none"
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleMenuClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: 200,
                  },
                }}
              >
                <MenuItem onClick={() => handleNavigate('/')}>
                  Browse cars
                </MenuItem>
                <MenuItem onClick={() => handleNavigate('/listcar')}>
                  List a car
                </MenuItem>
                {!isLoggedIn && (
                  <MenuItem onClick={() => handleNavigate('/signup')}>
                    Register dealership
                  </MenuItem>
                )}
                {!isLoggedIn && (
                  <MenuItem onClick={() => handleNavigate('/login')}>
                    Login
                  </MenuItem>
                )}
                {isLoggedIn && (
                  <MenuItem onClick={() => handleNavigate('/account')}>
                    My Cars
                  </MenuItem>
                )}
                {isLoggedIn && (
                  <MenuItem onClick={this.handleClick.bind(this)}>
                    Logout
                  </MenuItem>
                )}
              </Menu>
            </div>
          </Container>
        )}
      </Consumer>
    );
  }
}

export default Nav;
